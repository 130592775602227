import React from "react";

import Tina from "../images/tina.jpg";
import Facebook_tiny from "../images/facebook_tiny.png";
import Adresse from "../images/adresse.png";
import Phone from "../images/phone.png";

export default function Contact() {
  return (
    <section className="contact" id="contact">
      <h2>Kontakt</h2>
      <div className="contactContent">
        <div className="contactContent1">
          <img src={Tina} alt="Tina Lybæk" />
        </div>
        <div className="contactContent2">
          <p>
            <img src={Facebook_tiny} alt="Facebook_tiny" />
            Facebook: Pilates v/Tina Lybæk Grønne eller{"\u00A0"}
            <a
              href="https://www.facebook.com/pilatesvtina/"
              target="_blank"
              rel="noreferrer"
            >
              HER
            </a>
          </p>
          <p>
            <img src={Phone} alt="Phone" />
            Mobil Nummer: 40 14 61 67
          </p>
          <p>
            <img src={Adresse} alt="Adresse" />
            Adresse:
            <a
              href="https://www.google.com/maps/place/Terlings+Alle+2,+6000+Kolding/@55.4654005,9.4713103,17.75z/data=!4m6!3m5!1s0x464ca1c591139551:0x8eb2ab768aa9e2f0!8m2!3d55.465466!4d9.473095!16s%2Fg%2F11cscjnzxs?entry=ttu"
              alt="addresse"
              target="_blanc"
            >
              Terlingsalle 2, kælderen. 6000 Kolding.
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}
