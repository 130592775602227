import React from "react";

export default function Team() {
  return (
    <section className="teamContainer" id="team">
      <h2>Hold Tider</h2>
      <table className="classContainer">
        <tbody>
          <tr>
            <th>Mandag</th>
            <th>Onsdag</th>
          </tr>
          <tr>
            <td>16.30-17.45</td>
            <td>16.30-17.45</td>
          </tr>
          <tr>
            <td>18.00-19.15</td>
            <td>18.00-19.15</td>
          </tr>
          <tr>
            <td>19.30-20.45</td>
          </tr>
        </tbody>
      </table>
    </section>
    // <br></br> <span className="smallText">(Ingen Ledige Tider)</span>
  );
}
