import React from "react";
import Tabs from "./Tabs";

export default function Program() {
  return (
    <section className="program" id="program">
      <h2>Træningen</h2>
      <Tabs>
        <div label="Formål">
          <ul>
            <li>
              Det primære formål med pilates er at skabe et stærkt korset-ryg,
              mave og bækken.
            </li>
            <li>
              Pilates styrker og smidiggør hele kroppen og udnytter åndedrættet
              og koncentrationen til at kontrollere musklerne i nøjagtige og
              effektive bevægelser. Krop og sind forbindes.
            </li>
            <li>
              Musklerne arbejder statisk og dynamisk således at kroppen bliver
              stærk i alle bevægelser og skaber et godt fundament at bevæge sig
              ud fra.
            </li>
          </ul>
        </div>
        <div label="Fordele">
          <ul>
            <li>
              Fordelene ved pilates er at du efter bare få gange vil kunne mærke
              og se en stor forandring i kroppen:
            </li>
            <ul className="indent">
              <li>Mere energi</li>
              <li>Kropsbevidsthed</li>
              <li>Smidighed</li>
              <li>Bedre balance</li>
              <li>Flot kropsholding</li>
              <li>Udholdenhed</li>
              <li>Og en tonet krop</li>
            </ul>
            <li>Pilates er stærkt vanedannende på den gode måde.</li>
          </ul>
        </div>
        <div label="Øvelser">
          <ul>
            <li>Øvelserne foregår primært på måtte og vi gør ofte brug af:</li>
            <ul className="indent">
              <li>Foam roller</li>
              <li>Redondobold</li>
              <li>Håndvægte</li>
              <li>Flexbånd</li>
              <li>Bolde til bindevævs massage</li>
            </ul>
            <li>Bindevævsøvelser er også flettet ind i træningen.</li>
          </ul>
        </div>
        <div label="Åndedrættet">
          <p>
            At have en god vejrtrækning er super vigtigt, men hvor ofte tænker
            du på om du trækker vejret rigtigt? Ilt er det vigtigste næringsstof
            til kroppen, herfra kommer 90 % af vores energi. Desuden stimuleres
            det parasympatiske nervesystem ved det dybe åndedræt og derved
            sænkes stressniveauet.
          </p>
          <p>
            Jeg har deltaget i et fantastisk åndedrætskursus med Lotte Paarup
            fra "Den intelligente krop" og har lært vigtigheden af et godt
            åndedræt, grundene til at åndedrættet sommetider forandrer sig så vi
            ikke får fuld udbytte af det og hvordan vi kan få det meget bedre
            hvis vi bare trækker vejret lidt bedre. Det formidler jeg videre, på
            forskellige måder, i alle mine timer og herved skabes en bevidsthed
            omkring vejrtrækningen, som du kommer til at få gavn af hver dag,
            ikke kun under træningen.
          </p>
        </div>
      </Tabs>
    </section>
  );
}
