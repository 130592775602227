import React from "react";

export default function Price() {
  return (
    <section className="priceContainer" id="price">
      <div>
        <h2>Hold Info</h2>
        <p>
          Der er max 12 på holdet. Hvis du er forhinderet i at komme på det hold
          du er tilmeldt, må du gerne komme på et andet hvis der er plads.
        </p>
        <p>Hvis du starter inde i et forløb, regner jeg en pris ud til dig.</p>
      </div>
      <div>
        <h2>Betaling</h2>
        <p>
          Mobilpay er muligt, men pga. nye regler må jeg ikke oplyse nummeret
          her. Mobilpay erhvers nummer oplyses på sms når du tilmelder dig.
        </p>
        <p>
          Bankoverførsel på kontonummer: 3409- 0011505953- Skriv venligst dit
          navn i kommentarfeltet.
        </p>
        <p>OBS: Betaling er bindende og refunderes ikke.</p>
        <p>
          Hvis du er i tvivl om noget så skriv/ring endelig til mig på 40146167.
        </p>
      </div>
    </section>
  );
}
