import React from "react";

import Facebook from "../images/facebook.png";

export default function Footer() {
  return (
    <footer>
      <div>
        <p>Addresse: Terlingsalle 2, kælderen. 6000 Kolding.</p>
        <p>Telefon: 40 14 61 67</p>
        <small>Copyright &copy; Pilate Kolding v/ Tina Lybæk Grønne</small>
      </div>
      <a href="https://www.facebook.com/pilatesvtina/" target="_blanc">
        <img src={Facebook} alt="facebook" />
      </a>
    </footer>
  );
}
