import React from "react";
import { BrowserRouter } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import Logo from "../images/logo.png";

import { useEffect, useState } from "react";

export default function Header() {
  const [scrollPos, setScrollPos] = useState();
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  const styles = {
    close_span1:{
      transform: mobileMenuVisible ? "rotate(45deg) translate(6px, 5px)" : "none"
    },
    close_span2:{
      opacity: mobileMenuVisible ? "0" : "1"
    },
    close_span3:{
      transform: mobileMenuVisible ? "rotate(-45deg) translate(6px, -5px)" : "none"
    },
  }

  function handleMobileMenuClick() {
    setMobileMenuVisible((mobileMenuVisible) => !mobileMenuVisible);
  }

  useEffect(() => {
    function handleScroll() {
      setScrollPos(window.scrollY);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <BrowserRouter>
      <header
        className={`${
          scrollPos > 0 ? "headerContainer-scroll" : "headerContainer"
        } ${mobileMenuVisible ? "mobileHeader" : ""}`}
      >
        <div className="imgContainer">
          <img
            src={Logo}
            alt="logo"
            className={scrollPos > 0 ? "headerLogo-scroll" : "headerLogo"}
          />
        </div>
        <nav className="navLinks">
          <HashLink smooth to="#hero">
            Home
          </HashLink>
          <HashLink smooth to="#team">
            Hold
          </HashLink>
          <HashLink smooth to="#price">
            Priser
          </HashLink>
          <HashLink smooth to="#about">
            Om Mig
          </HashLink>
          <HashLink smooth to="#program">
            Træningen
          </HashLink>
          <HashLink smooth to="#contact">
            Kontakt
          </HashLink>
        </nav>
        <nav
          className={mobileMenuVisible ? "header-nav-mobile visible" : "hidden"}
        >
          <HashLink smooth to="#hero" onClick={handleMobileMenuClick}>
            Home
          </HashLink>
          <HashLink smooth to="#team" onClick={handleMobileMenuClick}>
            Hold
          </HashLink>
          <HashLink smooth to="#price" onClick={handleMobileMenuClick}>
            Priser
          </HashLink>
          <HashLink smooth to="#about" onClick={handleMobileMenuClick}>
            Om Mig
          </HashLink>
          <HashLink smooth to="#program" onClick={handleMobileMenuClick}>
            Træningen
          </HashLink>
          <HashLink smooth to="#contact" onClick={handleMobileMenuClick}>
            Kontakt
          </HashLink>
        </nav>
        <button className="hamburger-menu" onClick={handleMobileMenuClick}>
          <span style={styles.close_span1}></span>
          <span style={styles.close_span2}></span>
          <span style={styles.close_span3}></span>
        </button>
      </header>
    </BrowserRouter>
  );
}
