import React from "react";
import Spring from "../images/spring.jpg";
import Summer from "../images/summer.jpg";
import Fall from "../images/fall.jpg";
import Winter from "../images/winter.jpg";

export default function Season() {
  return (
    <section className="seasonContainer" id="season">
      <div>
        <img src={Summer} alt="season" />
        {/* <h2>Sommersæson 2024</h2> */}
        <p>Sommerferie fra uge 27-32. Begge uger inkl.</p>
        <p>
          Sensommer sæson starter mandag d 12 august i uge 33-41. <br></br>9
          ugers træning med en ugentlig træningsdag til 990 kr.
        </p>
        <p> Efterårsferie i uge 42.</p>
        <p>
          {" "}
          Efterårssæson uge 43-51. <br></br> 9 ugers træning med en ugentlig
          træningsdag til 990 kr.{" "}
        </p>
      </div>
    </section>
  );
}
